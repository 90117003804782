import WebServiceRequest from '../Api/WebServiceRequest'

class AcademyCommentGetByProductIdForAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('AcademyComment/GetByAcademyIdForAdmin')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class AcademyCommentGetAllForAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('AcademyComment/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class DeleteAcademyComment extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('AcademyComment/DeleteAcademyComment')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class ChangeAcademyCommentActivation extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('AcademyComment/ChangeCommentActivation')
    }
    setData(data){
        super.setRequestParamDataObj(data)
    }
}
export  {
    AcademyCommentGetByProductIdForAdmin,
    AcademyCommentGetAllForAdmin,
    DeleteAcademyComment,
    ChangeAcademyCommentActivation
};
